import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import Ins from './containers/Inscriptions_st';
import reportWebVitals from './reportWebVitals';
import LoginPage from './containers/Login';
import Updateinfost from './containers/Updateinfosta';
import NotFoundPage from './containers/NotFoundPage';
import ValidationStEtab from './containers/ValidationStEtab';
import ETATCertificatScolaritePDF from './containers/ETATCertificatScolaritePDF';

import ETATCertificatInscriptionPDF from './containers/ETATCertificatInscriptionPDF';
import Preinscriptionpdf from './containers/Preinscriptionpdf';
import Pre_innovationpdf from './containers/Pre_innovationpdf';



import LoginEtaba from './containers/LoginEtab';
import ChangPwStEtab from './containers/ChangPwStEtab';
import ChangPwStDfep from './containers/ChangPwStDfep';
import ChangPwStMfep from './containers/ChangPwStMfep';
import OffersMfep from './containers/OffersMfep';
import OffersDfep from './containers/OffersDfep';
import Inscription from './containers/Inscription';
import SignUpInnovation from './containers/SignUpInnovation';



import InfoCand from './containers/InfoCand';
import ValidationCandInfo from './containers/ValidationCandInfo';
import SettingEtab from './containers/SettingEtab';

import NotesApr from './containers/NotesApr';

import SettingDfep from './containers/SettingDfep';
import SettingMfep from './containers/SettingMfep';
import OffersEtab from './containers/OffersEtab';
import SessionsMfep from './containers/SessionsMfep';




import ShowAllAprs from './containers/ShowAllAprs';
import ShowAllAprsDfep from './containers/ShowAllAprsDfep';
import ShowAllAprsMfep from './containers/ShowAllAprsMfep';
import ShowApiList from './containers/ShowApiList';
import Offersinscription from './containers/Offersinscription';

import useAuthentication_etab from './containers/useAuthentication_etab';

import useAuthentication_cand from './containers/useAuthentication_cand';
import useAuthentication_apisuser from './containers/useAuthentication_apisuser';



import SettingPriv from './containers/SettingPriv';

import HomeDfep from './containers/HomeDfep';
import HomeMfep from './containers/HomeMfep';
import Apis_userslist from './containers/Apis_userslist';
import Apis_functions from './containers/Apis_functions';
import Apis_userlist_functions from './containers/Apis_userlist_functions';



import ChangePassword from './containers/ChangePassword';
import ChangePwEtab from './containers/ChangePwEtab';
import CheckAuth from './containers/CheckAuth';

import CameraComponent from './containers/CameraComponent';


import AppHtml5qrcode from './containers/AppHtml5qrcode';
import ApiTestComponent from './containers/ApiTestComponent';
import Migrate_hfsql from './containers/Migrate_hfsql';
import Jscookiestest from './containers/Jscookiestest';

import Proposition_specialist from './containers/Proposition_specialist';
import InnovationList from './containers/InnovationList';
import HomePageMfp from './containers/HomePageMfp';




import { createRoot } from 'react-dom/client';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const root = createRoot(document.getElementById('root'));

root.render(

  <Router   >   {/* basename="/moutakouin" "homepage": "/moutakouin", */}
    <Routes>
    
    <Route path="/HomePageMfp" element={<HomePageMfp />} />
    
    <Route path="/Proposition_specialist" element={<Proposition_specialist />} />
      <Route path="/Login" element={<LoginPage />} />
      <Route path="/LoginEtab" element={<LoginEtaba />} />
      <Route path="/ChangPwStEtab" element={<ChangPwStEtab />} />
      <Route path="/ChangPwStDfep" element={<ChangPwStDfep />} />
      <Route path="/ChangPwStMfep" element={<ChangPwStMfep />} />
      <Route path="/OffersMfep" element={<OffersMfep />} />
      <Route path="/OffersDfep" element={<OffersDfep />} />
      <Route path="/Migrate_hfsql" element={<Migrate_hfsql />} />
      <Route path="/Inscription/:IDOffre?/:IDsession_selected?/:IDDFEP_selected?" element={<Inscription />} />

      
      <Route path="/InnovationList" element={<InnovationList />} />

      <Route path="/SignUpInnovation" element={<SignUpInnovation />} />

      <Route path="/Offersinscription" element={<Offersinscription />} />
      <Route path="/useAuthentication_etab" element={<useAuthentication_etab />} />
      <Route path="/useAuthentication_cand" element={<useAuthentication_cand />} />
      <Route path="/useAuthentication_apisuser" element={<useAuthentication_apisuser />} />

      <Route path="/OffersEtab" element={<OffersEtab />} />
      <Route path="/SessionsMfep" element={<SessionsMfep />} />


      <Route path="/Apis_userslist" element={<Apis_userslist />} />
      <Route path="/Apis_functions" element={<Apis_functions />} />
      <Route path="/Apis_userlist_functions" element={<Apis_userlist_functions />} />
      <Route path="/ApiTest" element={<ApiTestComponent />} />
      <Route path="/ShowApiList" element={<ShowApiList />} />
      <Route path="/Jscookiestest" element={<Jscookiestest />} />

      

      <Route path="/SettingDfep" element={<SettingDfep />} />
      <Route path="/SettingMfep" element={<SettingMfep />} />


      <Route path="/HomeDfep" element={<HomeDfep />} />

      <Route path="/HomeMfep" element={<HomeMfep />} />



      <Route path="/ShowAllAprs" element={<ShowAllAprs />} />
      <Route path="/ShowAllAprsDfep" element={<ShowAllAprsDfep />} />
      <Route path="/ShowAllAprsMfep" element={<ShowAllAprsMfep />} />



      <Route path="/SettingPriv" element={<SettingPriv />} />
      <Route path="/ChangePassword" element={<ChangePassword />} />
      <Route path="/ChangePwEtab" element={<ChangePwEtab />} />
      <Route path="/CheckAuth" element={<CheckAuth />} />
      <Route path="/CameraComponent" element={<CameraComponent />} />

      <Route path="/AppHtml5qrcode" element={<AppHtml5qrcode />} />


      <Route path="/InfoCand" element={<InfoCand />} />
      <Route path="/ValidationCandInfo" element={<ValidationCandInfo />} />
      <Route path="/SettingEtab" element={<SettingEtab />} />

      <Route path="/Updateinfost" element={<Updateinfost />} />
      <Route path="/404" element={<NotFoundPage />} />


      <Route path="/ValidationStEtab" element={<ValidationStEtab />} />
      <Route path="/ETATCertificatScolaritePDF/:cryptedId" component={ETATCertificatScolaritePDF} element={<ETATCertificatScolaritePDF />} />


      <Route path="/ETATCertificatInscriptionPDF/:cryptedId" component={ETATCertificatInscriptionPDF} element={<ETATCertificatInscriptionPDF />} />
      
      <Route path="/Preinscriptionpdf/:cryptedId" component={Preinscriptionpdf} element={<Preinscriptionpdf />} />
      
      <Route path="/Pre_innovationpdf/:cryptedId" component={Pre_innovationpdf} element={<Pre_innovationpdf />} />

      <Route path="/NotesApr" element={<NotesApr />} />

      <Route path="/SignUp" element={<Ins />} />
      <Route path="/" element={<LoginPage />} />

      {/* Add more routes as needed */}
    </Routes>
  </Router>

);

reportWebVitals();
